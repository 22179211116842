import React, { useEffect, useState, useContext, useRef } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import SetTPSL from 'components/ui/SetTPSL';
import { makeTrade } from 'api/socketService';
import { formatCurrency } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import { v4 as uuidv4 } from 'uuid';

import SocketContext from 'utils/hooks/useSocket';

import {
  Row,
  RowBelt,
  Text,
  Amount,
  InputRow,
  Info,
  InputRange,
  InputText,
  LineRange,
  Percentages,
  LoadWrap,
  LoadButton,
  CheckMark,
  FlexRow,
  ButtonWrap,
} from './styled';

const OpenShort = (props) => {
  const [rangeval, setRangeval] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [coinInputValue, setCoinInputValue] = useState('');
  const [usdtInputValue, setUsdtInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(null);
  const [isTrading, setIsTrading] = useState(false);
  const { tournamentDetails } = props;
  const { walletData, update } = useWalletData();
  const { triggerTradeUpdate } = useTradeUpdate();
  const usdtWallet = walletData.find(wallet => wallet.ticker.short_name === 'USDT');
  const availableUsdt = usdtWallet ? usdtWallet.amount : 0;
  const {selectedCoinValue, selectedCoin } = useContext(SocketContext);
  const lastClickTimestamp = useRef(0);
  const [isOpenTpSl, setOpenTpSl] = useState(true);
  const [isLimitActive, setIsLimitActive] = useState(false);

  let shortNameSelectedCoin = selectedCoin?.name?.split('/')[0]
  let price = selectedCoinValue.last;
  let precision = selectedCoin?.base?.data?.attributes?.precision ? selectedCoin?.base?.data?.attributes?.precision : selectedCoin?.precision
  if(precision > 8){precision = 8;}
  const disabledBTN = availableUsdt?.toFixed(precision) <= 0;

  const leverageOptions = tournamentDetails?.data?.attributes?.leverage || [];

  useEffect(() => {
    if (!inputFocused) {
      const newUsdtAmount = (rangeval / 100) * availableUsdt;
      setUsdtAmount(newUsdtAmount);
      setUsdtInputValue(newUsdtAmount.toFixed(2));
    }
  }, [rangeval, price, inputFocused]);

  const handleBtcInputFocus = () => {
    setCoinInputValue(''); // Clear BTC input on focus
    setInputFocused(shortNameSelectedCoin);
  };

  const handleUsdtInputFocus = () => {
    setUsdtInputValue(''); // Clear USDT input on focus
    setInputFocused('USDT');
  };

  const handleBtcInputBlur = () => {
    setInputFocused(null);
    // If BTC input is not empty, keep the formatted value
    if (coinInputValue === '') {
      setUsdtAmount(0); // Also reset the USDT amount
    }
  };

  const handleUsdtInputBlur = () => {
    setInputFocused(null);
    // If USDT input is not empty, keep the formatted value
    if (usdtInputValue === '') {
      setUsdtAmount(0); // Ensure the numeric state is zero when input is empty
    }
  };

  const handleBtcInputChange = (value) => {
    const numericValue = value === '' ? 0 : Number(value);
    setCoinInputValue(value); // Update display value

    // Calculate USDT amount based on BTC input
    let newUsdtAmount = numericValue * price;
    // If the new USDT amount exceeds the maximum, cap it
    if (newUsdtAmount > availableUsdt) {
      newUsdtAmount = availableUsdt;
      setCoinInputValue((newUsdtAmount / price).toFixed(precision)); // Update the BTC input to capped value
    }
    setUsdtAmount(newUsdtAmount);
    setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update USDT input display

    // Update slider value
    const sliderValue = (newUsdtAmount / availableUsdt) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100); // Cap the slider at 100%
  };

  const handleUsdtInputChange = (value) => {
    const numericValue = value === '' ? 0 : Number(value);
    setUsdtInputValue(value); // Update display value
    let newUsdtAmount = numericValue; // Update numeric amount for calculations

    // If the new USDT amount exceeds the maximum, cap it
    if (newUsdtAmount > availableUsdt) {
      newUsdtAmount = availableUsdt;
      setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update the USDT input to capped value
    }
    setUsdtAmount(newUsdtAmount);

    // Update slider value
    const sliderValue = (newUsdtAmount / availableUsdt) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100); // Cap the slider at 100%
  };

  const handleMarketClick = () => {
    setIsLimitActive(false);
  };

  const handleLimitClick = () => {
    setIsLimitActive(true);
  };

  const handleBuyClick = () => {
    if (isTrading) {
      return;
    }
    const now = Date.now();
    setIsTrading(true);

    if (now - lastClickTimestamp.current < 1000) {
      return;
    }

    lastClickTimestamp.current = now;

    const tradePayload = {
      tournament: tournamentDetails?.data?.id,
      tradingPair: selectedCoin?.name,
      ticker: "USDT",
      type: "buy",
      value: Number(usdtAmount.toFixed(2)),
      token: uuidv4(),
    };

    setIsTrading(true);

    makeTrade(tradePayload, (response) => {
      update();
      triggerTradeUpdate();
      resetValues();
      setIsTrading(false);
    });
  };

  const resetValues = () => {
    setRangeval(0);
    setUsdtAmount(0);
  };


  return (
    <>
      {!isOpenTpSl &&
        {/* <SetTPSL/> */}
      }
      {isOpenTpSl &&
        <>
        <Info>
          <Text>Available funds<span> {formatCurrency(availableUsdt)} USDT</span></Text>
        </Info>

        <Row className="RowRadio">
          <label>
            <input className="InputRadio" type="radio" name="market" />
            <CheckMark className="checkmark">X1</CheckMark>
          </label>

          <label>
            <input className="InputRadio" type="radio" name="market" disabled />
            <CheckMark className="checkmark">Leverage</CheckMark>
          </label>

          {leverageOptions.map((leverage, index) => (
            <label key={index}>
              <input className="InputRadio" type="radio" name="market" />
              <CheckMark className="checkmark">{leverage}</CheckMark>
            </label>
          ))}
        </Row>
        <RowBelt>
        <FlexRow>
          <ButtonFilled
            className={`ButtonSmall btnMarket ${!isLimitActive ? 'isActive' : ''}`}
            type="button"
            onClick={handleMarketClick}
          >
            Market
          </ButtonFilled>
          <ButtonFilled
            className={`ButtonSmall btnLimit ${isLimitActive ? 'isActive' : ''}`}
            type="button"
            onClick={handleLimitClick}
          >
            Limit
          </ButtonFilled>
        </FlexRow>

          <ButtonFilled
            className="ButtonSmall"
            type="button"
            onClick={() => setOpenTpSl(!isOpenTpSl)}
          >
            TP/SL
          </ButtonFilled>
        </RowBelt>

        {!isLimitActive &&
          <Row className="DarkInfo">
            <Text>Market price</Text>
            <Amount>{price} USDT</Amount>
          </Row>
        }

        {isLimitActive &&
          <Row className="RowInput">
            <Text className="TextSpecial">Price</Text>
            <InputText
              type="number"
            />
            <Amount className="AmountSpecial">USDT</Amount>
          </Row>
        }

        <InputRow>
          <LineRange style={{ width: `${rangeval}%` }}>
            <Percentages className={!usdtAmount ? "" : "active"}>
              {/* Guard against NaN by checking if rangeval is a number */}
              <span>{!isNaN(rangeval) ? `${rangeval.toFixed(0)}%` : '0%'}</span>
            </Percentages>
          </LineRange>
          <InputRange
            disabled={disabledBTN}
            type="range"
            value={rangeval}
            min="0"
            max="100"
            onChange={(event) => {
              const value = Number(event.target.value);
              setRangeval(value); // Update the slider value

              // Perform calculations based on the slider value
              const newUsdtAmount = (value / 100) * availableUsdt;
              setUsdtAmount(newUsdtAmount); // Update numeric USDT amount
              setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update USDT input display
            }}
          />
        </InputRow>

        <Row className="RowInput">
          <Text className="TextSpecial">Amount</Text>
          <InputText
            type="number"
            pattern="\d*(\.\d+)?"
            step="0.0001"
            min="0"
            value={coinInputValue}
            onFocus={handleBtcInputFocus}
            onBlur={handleBtcInputBlur}
            onChange={(e) => handleBtcInputChange(e.target.value)}
          />
          <Amount className="AmountSpecial">{shortNameSelectedCoin}</Amount>
        </Row>
        <Row className="RowInput">
          <Text className="TextSpecial">Sum</Text>
          <InputText
            type="number"
            pattern="\d*(\.\d+)?"
            step="0.01"
            min="0"
            value={usdtInputValue}
            onFocus={handleUsdtInputFocus}
            onBlur={handleUsdtInputBlur}
            onChange={(e) => handleUsdtInputChange(e.target.value)}
          />
          <Amount className="AmountSpecial">USDT</Amount>
        </Row>

        <ButtonWrap>
          {isTrading ?
            <LoadButton>
              <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
            </LoadButton>
          :
            <ButtonFilled
              type="button"
            >
              Open Short
            </ButtonFilled>
          }
        </ButtonWrap>
        </>
      }
    </>
  );
};

export default OpenShort;
