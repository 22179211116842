import styled from 'styles/styled';

export const Amount = styled.h6`
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Wrap = styled.div`
  @media (min-width: 920px) {
    min-height: 301px;
    padding-top: 24px;
  }
`;

export const InputText = styled.input`
  background-color: transparent;
  outline: none;
  height: 36px;
  padding: 0 45px 0 60px;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  color: #fefefe;
  line-height: 24px;
  @media (max-width: 720px) {
    height: 30px;
    border-radius: 8px;
    margin-bottom: 5px;
    padding-top: 6px;
    font-size: 12px;
  }
  &::placeholder {
    color: #fefefe;
  }
`;

export const Row = styled.div`
  background-color: #292B2E;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
  height: 36px;
  margin-bottom: 8px;
  @media (max-width: 720px) {
    height: 30px;
    border-radius: 8px;
    margin-bottom: 5px;
  }
  &.DarkInfo {
    h6 {
      color: #989898;
    }
  }
  &.RowRadio {
    @media (max-width: 390px) {
      padding: 0 4px;
    }
  }
  .InputRadio {
    display: none;
    &:checked ~ .checkmark {
      background-color: #DBB230;
      color: #000;
    }
  }
  &.RowInput {
    position: relative;
    padding: 0 8px;
    h6 {
      top: 0;
      position: absolute;
      &.TextSpecial {
        left: 13px;
        padding: 6px 0 0 0;
        pointer-events: none;
      }
      &.AmountSpecial {
        right: 13px;
        padding: 6px 0 0 0;
        pointer-events: none;
      }
    }
  }
`;

export const Text = styled.h6`
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const LoadButton = styled.div`
  button.buttonLoad {
    :disabled {
      color: #aaa;
      font-size: 12px;
    }
  }
`;

export const LoadWrap = styled.div`
  display: block;
  width: 15px;
  height: 15px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 0.6px 0.5px 0 0.6px #aaa;
  margin-right: 5px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const TextModal = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 15px;
`;

export const TitleModal = styled.h3`
  font-size: 18px;
  color: #EFEFEF;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    width: calc(50% - 3px);
    &:first-child {
      button {
        background-color: transparent;
        color: #52D381;
        border: 1px solid #52D381;
      }
    }
  }
`;
