import { io } from "socket.io-client";
import { getTournamentRanking } from "./tournamentService";
export let socket;
let prevCoin;

const tradeTypeEventMap = {
  Spot: "refreshTournamentRankingWithTotalInUSDT",
  Margin: "refreshMarginTournamentRankingWithTotalInUSDT",
};


export const initializeSocket = async (token, tournamentId, onConnect) => {
  if (!socket?.connected && tournamentId && token) {
    socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      withCredentials: true,
      transports: [ "websocket" ],
      query: {
        token: token,
        tournamentId: tournamentId,
      },
    });

    socket.on("connect", onConnect);
  }
  return socket;
};

export const makeTrade = (tradePayload, callback) => {
  if (socket) {
    socket.emit("makeTrade", tradePayload, callback);
  }
};

export const makeMargin = (tradePayload, callback) => {
  if (socket) {
    socket.emit("makeMargin", tradePayload, callback);
  }
};
export const closeMargin = (tradeId, callback) => {
  if (socket) {
    socket.emit("closeMargin", { id: tradeId }, callback);
  }
};  

export const cancelMargin = (tradeId, callback) => {
  if (socket) {
    socket.emit("cancelMargin", { id: tradeId }, callback);
  }
};  


export const getAllCoinsPrices = (
  coins,
  updateCoinsValue,
) => {
  const dataArray = {}
  if (socket) {
    const updateData = (currency) => (data) => {
      dataArray[currency] = {"price":data?.last, "data":data};
      updateCoinsValue({"key": currency, "data":data})
    };
    coins.forEach(coin => {
      let coinShortName = coin.attributes.name.split('/')[0].toLowerCase();
          
      socket.on(
        `${coinShortName}PriceUpdate`, 
        updateData(coinShortName)
      );
      socket.on(
        `${coinShortName}OHLCV`, 
        updateData(`${coinShortName}OHLCV`)
      );
    });
  }
}


export const getPriceBySelectedCoin = (
  selectedCoin,
  updateCoinsValue
) => {
  if (socket) {
    if(prevCoin && prevCoin !== selectedCoin){
      //socket.off(`${prevCoin}PriceUpdate`);
    }
    prevCoin = selectedCoin;
    const createPriceUpdateListener = (currency, updatePrice, save24hData) => (data) => {
      updateCoinsValue({"key":selectedCoin, "data":data});
    };
    socket.on(
      `${selectedCoin}PriceUpdate`,
      createPriceUpdateListener(`${selectedCoin}Test`, updateCoinsValue)
    );
  }

  
}

export const removePriceUpdateListeners = () => {
  if (socket) {
    socket.off("btcPriceUpdate");
    socket.off("ethPriceUpdate");
    socket.off("avaxPriceUpdate");
    socket.off("adaPriceUpdate");
    socket.off("bnbPriceUpdate");
    socket.off("btcOHLCV");
  }
};

export const disconnectSocket = (tradeType) => {
  if (socket) {
    removePriceUpdateListeners();
    removeRefreshTournamentRankingListener(tradeType);
    removeBtcOHLCVListener();
    socket.disconnect();
    socket = null;
  }
};

const tradeTypeMap = {
  Spot: "getTrading",
  Margin: "marginHistory"
};

export const setTradingUpdateListener = (tradeType, updateTrading) => {
  if (socket) {
    const eventName = tradeTypeMap[tradeType];

    if (eventName) {
      socket.on(eventName, (data) => {
        updateTrading(data);
      });
    }
  }
};

export const removeTradingUpdateListener = (tradeType) => {
  if (socket) {
    const eventName = tradeTypeMap[tradeType];
    if (eventName) {
      socket.off(eventName);
    }
  }
};

const tradeTypeEmitMap = {
  Spot: "getTrading",
  Margin: "historyMargin"
};

export const requestTradingData = (tournamentId, tradeType, callback) => {
  if (!tournamentId || !socket) return;
  const eventName = tradeTypeEmitMap[tradeType];
  if (eventName) {
    const payload = { tournament: tournamentId };
    socket.emit(eventName, payload, callback);
  }
};

export const requestWalletData = (tournamentId, callback) => {
  if (!tournamentId || !socket) return;
  socket.emit("getWallets", { tournament: tournamentId }, callback);
};

export const emitRefreshTournamentRanking = (tradeType, data, callback) => {
  const eventName = tradeTypeEventMap[tradeType];
  if (socket && eventName) {
    socket.emit(eventName, data, callback);
  }
};

export const setRefreshTournamentRankingListener = async (
  tradeType,
  tournamentId,
  callback
) => {
  const eventName = tradeTypeEventMap[tradeType];
  if (socket && eventName) {
    socket.on(eventName, async (data) => {
      if (data && Object.keys(data).length > 0) {
        callback(data);
      } else {
        const apiData = await getTournamentRanking(tournamentId);
        callback(apiData);
      }
    });
  } else {
    console.log("No socket available for real-time updates");
    try {
      const apiData = await getTournamentRanking(tournamentId);
      if (apiData && Object.keys(apiData).length > 0) {
        callback(apiData);
      }
    } catch (error) {
      console.error("Error fetching initial tournament ranking:", error);
    }
  }
  const apiData = await getTournamentRanking(tournamentId);
  callback(apiData);
};

export const removeRefreshTournamentRankingListener = (tradeType) => {
  const eventName = tradeTypeEventMap[tradeType];
  if (socket && eventName) {
    socket.off(eventName);
  }
};

export const setBtcOHLCVListener = (callback) => {
  if (socket) {
    socket.on("btcOHLCV", (data) => {
      callback(data);
    });
  }
};

export const removeBtcOHLCVListener = () => {
  if (socket) {
    socket.off("btcOHLCV");
  }
};

export const emitChangeOHLCVTimeframe = (payload, callback) => {
  if (socket) {
    socket.emit("changeOHLCVtimeframe", payload, callback);
  }
};
