import styled from 'styles/styled';
import imgBanner from '../../../assets/banner.png';
import imgBannerCasper from '../../../assets/casper.png';
import imgBannerBitomat from '../../../assets/bitomat.png';
import imgBannerBitget from '../../../assets/Bitget.png';
import imgBannerKryptoSilesia from '../../../assets/ksbr.png';
import imgBannerBL from '../../../assets/bw_banner.png';

export const Wrap = styled.div`
  background-color: #2e2e2e;
  width: 350px;
  height: 42px;
  border-radius: 10px;
  margin: 12px 0 4px 0;
  position: relative;
  background-image: url(${imgBanner});
  background-size: cover;
  background-position: center 68%;
  &.banner_casper {
    background-image: url(${imgBannerCasper});
  }
  &.banner_bitomat {
    background-image: url(${imgBannerBitomat});
  }
  &.banner_bitget {
    background-image: url(${imgBannerBitget});
  }
  &.banner_KryptoSilesia {
    background-image: url(${imgBannerKryptoSilesia});
  }
  &.banner_BL {
    background-image: url(${imgBannerBL});
  }
  @media (max-width: 1150px) {
    margin-top: 10px;
  }
  @media (max-width: 720px) {
    border-radius: 8px;
    width: calc(100% - 130px);
    margin: 0 auto;
    top: 11px;
    height: 34px;
    background-position: center;
    background-size: cover;
    max-width: 220px;
  }
  &:after {
    content: "Sponsored by:";
    position: absolute;
    top: -10px;
    left: 12px;
    font-weight: 400;
    line-height: 8px;
    font-size: 6px;
    color: #fff;
    @media (max-width: 1280px) {
      bottom: -10px;
      right: 0;
      left: inherit;
      top: inherit;
    }
    @media (max-width: 720px) {
      left: inherit;
      right: 12px;
      top: -10px;
    }
  }
`;
