import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LineText } from '../TournamentRanking/styled';

type TournamentCountdownProps = {
  date: string;
};

export const TournamentCountdown = ({ date }: TournamentCountdownProps) => {
  const [timeLeft, setTimeLeft] = useState('');

  const calculateTimeLeft = () => {
    const now = moment().utc();
    const end = moment.utc(date);
    const duration = moment.duration(end.diff(now));

    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setTimeLeft(`${totalDays}d ${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(() => {

    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  }, [date]);

  if (timeLeft === '0d 0h 0m 0s' ) {
    window.location.reload();
  }

  return <LineText>{timeLeft}</LineText>;
};

export default TournamentCountdown;
