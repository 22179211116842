import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData } from 'api/socketService';
import { useWalletData } from 'context/WalletDataContext';
import SocketContext from 'utils/hooks/useSocket';
import { ButtonFilled } from 'components/ui/Button';

import {
  Box,
  Wrap,
  Td,
  Th,
  TableWrap,
  Table,
  Tab,
  Button,
  IconCurrency,
  BoxInfo,
  RowInfo,
  InfoMargin,
  Leverage,
  CurrencyInfo,
  SmallBox,
  SmallText,
  Text,
  EditBoxWrap,
  BoxButton,
  ListWrap,
  ListWrapCol,
  ListText,
  ListRightButton,
  ColLeft,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

const TradeHistoryMargin = (props: { tournamentDetails: any; }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };
  const [isOpenTransaction, setOpenTransaction] = useState(true);
  const { tournamentDetails } = props;
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { walletData } = useWalletData();
  const {allCoinsValues } = useContext(SocketContext);

  function calculatePerformance(data, selectedCoinPrice) {
    let totalCoins = 0;
    let totalCost = 0;
    let weightedPriceSum = 0
    let totalAmountBought = 0;

    data?.forEach(trade => {
      if (trade.type === 'buy') {
        totalCoins += trade.amount;
        totalCost += trade.price;
        weightedPriceSum += trade.amount * trade.market_value;
        totalAmountBought += trade.amount;
      } else if (trade.type === 'sell') {
        totalCoins -= trade.amount;
        totalCost -= trade.price;
      }
    });

    const avgPrice = weightedPriceSum / totalAmountBought;
    const currentValue = totalCoins * selectedCoinPrice; // Current total value in USDT
    const plUSDT = currentValue - totalCost
    const plPercent = (currentValue*100 / totalCost) - 100;

    return {
      totalCoins: totalCoins,
      totalCost: totalCost,
      avgPrice: avgPrice,
      currentValue: currentValue,
      plUSDT: plUSDT,
      plPercent: plPercent
    };
  }

  const updateTrading = (data) => {
    if (data.success) {
      setTradingData(data.data.trading);

    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    const tournamentId = tournamentDetails?.data?.id;
    const tradeType = tournamentDetails?.data?.attributes?.trade_type;
    if (tournamentId) {
      requestTradingData(tournamentId, tradeType, updateTrading);
    }
  };

  const [pairTradesData, updatePairTradesData] = useState(null)
  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.amount !== currentWalletData[i]?.amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  useEffect(()=>{
    fetchTradingData();
  },[currentWalletData])

  let tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');

  useEffect(() => {
    const pairTradesDataTemp = {};
    const pairTradesGroups = {};
    tradingData.forEach(trade => {
      const pair = trade.trading_pair;
      if (!pairTradesGroups[pair]) {
        pairTradesGroups[pair] = [];
      }
      pairTradesGroups[pair].push(trade);
      for (let key in pairTradesGroups) {
        let shortName = key.split('/')[0].toLowerCase();
        let pair = pairTradesGroups[key]
        if(allCoinsValues && allCoinsValues[shortName]){
          let data = calculatePerformance(pair, allCoinsValues[shortName].last);
          pairTradesDataTemp[key] = data
        }
      }
  });
  updatePairTradesData(pairTradesDataTemp)
}, [allCoinsValues, tradingData]);

  return (
    <Box className="TradeHistory">
      <Wrap>
        <Tab>
          <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Open</Button>
          <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Pending</Button>
          <Button className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>History</Button>
        </Tab>

        {activeTab === "tab1" ?
          <TableWrap className="largeSize">
            <ListWrap>
              <ColLeft>
                <ListWrapCol>
                  <IconCurrency />
                  <BoxInfo>
                    <RowInfo>
                      <InfoMargin className="up_color">Long</InfoMargin>
                      <Leverage>X1</Leverage>
                    </RowInfo>
                    <CurrencyInfo>BTC/USDT</CurrencyInfo>
                  </BoxInfo>
                </ListWrapCol>

                <ListText>
                  {!isOpenTransaction &&
                    <p className="info_text">Are you sure you want to close this position?</p>
                  }
                  {isOpenTransaction &&
                    <>
                      <Text><span>Profit</span><p className="up_color">833.16 USDT (+30.53%)</p></Text>
                      <Text><span>Used Funds</span><p>21,654.32 USDT</p></Text>
                      <Text><span>Amount</span><p>1,654.32 BTC</p></Text>
                      <Text><span>Price</span><p>61,654.32 USDT</p></Text>
                      <Text><span>Liquidation Price</span><p>21,654.32 USDT</p></Text>
                    </>
                  }
                </ListText>
              </ColLeft>

              <ListRightButton>
                {!isOpenTransaction &&
                  <EditBoxWrap>
                    <BoxButton>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        Yes
                      </ButtonFilled>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        No
                      </ButtonFilled>
                    </BoxButton>
                  </EditBoxWrap>
                }
                {isOpenTransaction &&
                  <>
                    <SmallBox>
                      <SmallText>Tap to edit</SmallText>
                      <div>
                        <Text className="up_color"><span>TP</span>27,680.00</Text>
                        <Text className="down_color"><span>SL</span>27,680.00</Text>
                      </div>
                    </SmallBox>
                    <ButtonFilled
                      className="button"
                      type="button"
                      onClick={() => setOpenTransaction(!isOpenTransaction)}
                    >
                      Close position
                    </ButtonFilled>
                  </>
                }
              </ListRightButton>
            </ListWrap>
          </TableWrap>
        : null }

        {activeTab === "tab2" ?
          <TableWrap>
            <ListWrap>
              <ColLeft>
                <ListWrapCol>
                  <IconCurrency />
                  <BoxInfo>
                    <RowInfo>
                      <InfoMargin className="down_color">Short</InfoMargin>
                      <Leverage>X10</Leverage>
                    </RowInfo>
                    <CurrencyInfo>BTC/USDT</CurrencyInfo>
                  </BoxInfo>
                </ListWrapCol>

                <ListText>
                  {!isOpenTransaction &&
                    <p className="info_text">Are you sure you want to cancel this order?</p>
                  }
                  {isOpenTransaction &&
                    <>
                      <Text><span>Limit</span><p>833.16 USDT (+30.53%)</p></Text>
                      <Text><span>Used Funds</span><p>21,654.32 USDT</p></Text>
                      <Text><span>Amount</span><p>1,654.32 BTC</p></Text>
                    </>
                  }
                </ListText>
              </ColLeft>

              <ListRightButton>
                {!isOpenTransaction &&
                  <EditBoxWrap>
                    <BoxButton>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        Yes
                      </ButtonFilled>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        No
                      </ButtonFilled>
                    </BoxButton>
                  </EditBoxWrap>
                }
                {isOpenTransaction &&
                  <>
                    <SmallBox>
                      <SmallText>Tap to edit</SmallText>
                      <div>
                        <Text className="up_color"><span>TP</span>27,680.00</Text>
                        <Text className="down_color"><span>SL</span>27,680.00</Text>
                      </div>
                    </SmallBox>
                    <ButtonFilled
                      className="button"
                      type="button"
                      onClick={() => setOpenTransaction(!isOpenTransaction)}
                    >
                      Cancel order
                    </ButtonFilled>
                  </>
                }
              </ListRightButton>
            </ListWrap>
          </TableWrap>
        : null }

        {activeTab === "tab3" ?
          <TableWrap className="largeSize">
            <Table>
              <thead>
                <tr>
                  <Th>Trading pair</Th>
                  <Th>Type</Th>
                  <Th>Leverage</Th>
                  <Th>Used funds</Th>
                  <Th>Amount</Th>
                  <Th>Price</Th>
                  <Th>Profit</Th>
                  <Th></Th>
                </tr>
              </thead>
              <tbody>
                {tradingPairWithAmount.map(pair => (
                  <tr key={pair.id}>
                    <Td>BTC/USDT</Td>
                    <Td>Limit/Open Long</Td>
                    <Td className="detail_state">X10</Td>
                    <Td>2,728.98 USDT</Td>
                    <Td>26,000.00 USDT</Td>
                    <Td>0.07767212 BTC</Td>
                    <Td className="up_state">10,960.99 USDT</Td>
                    <Td><span>Cancelled</span></Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrap>
        : null }
      </Wrap>
    </Box>
  );
};
export default TradeHistoryMargin;
