import React, { useState } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { useTournament } from 'context/TournamentContext';
import * as routes from 'constants/routes';
import Cookies from 'js-cookie';
import { joinTournament } from 'api/tournamentService';
import { useUser } from 'api/userService';
import { getCookieDomainFromUrl } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import WinInformation from 'components/ui/WinInformation';
import WinInformationBitoMat from 'components/ui/WinInformationBitoMat';
import WinInformationCryptoSilesia from 'components/ui/WinInformationCryptoSilesia';
import WinInformationBitGet from 'components/ui/WinInformationBitGet';

import {
  ContentInformation,
  RowInformation,
  Text,
  Position,
  SubText,
  Title,
  SubTitle,
  ContentInformationCenter,
  Wrap,
  WinWrap,
} from './styled';

const JoinEnd = (props: { tournamentDetails: any; }) => {
  const { joinedStatus, tournamentDetails, tournamentStatus, tournamentPairList } = useTournament();
  const attributes = tournamentDetails?.data?.attributes;
  const participantsCount = attributes?.participants?.data?.length;
  const rewardPool = tournamentDetails?.data?.attributes?.reward_pool;
  const rewardPoolCurrency = tournamentDetails?.data?.attributes?.reward_pool_currency || 'USDT';
  const displayRewardPool = rewardPool > 0 ? rewardPool + ' ' + rewardPoolCurrency: '---';
  const { totalUSDTString } = useWalletData();
  const { rank } = useSocket();
  const endDate = new Date(attributes.end_at).toLocaleDateString();
  const initialMoney = attributes.amount_start || 1;
  const percentageChange = ((totalUSDTString - initialMoney) / initialMoney) * 100;
  const parseTotalMoney = formatCurrency(totalUSDTString);
  const percentageTotalss = (totalUSDTString - initialMoney);

  const { isLoggedIn } = useUser();
  const navigate = useNavigate();
  const tournamentId = tournamentDetails?.data?.id;
  const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
  const [isload, setLoad] = useState(false);
  const tradingPair = tournamentPairList;

  var defeatInfo;
  var winPerson;
  var winPersonBitGet;
  var winPersonBitoMat;
  var winPersonCryptoSilesia;

  if (rank === 0 || rank > 3) {
    defeatInfo = <SubTitle className="SecondSubTitle">Thank you!</SubTitle>;
  } else {
    winPerson = <WinInformation/>;
  }

  if (tournamentId === 85) {
    if (rank === 0 || rank > 33) {
      defeatInfo = <SubTitle className="SecondSubTitle">Thank you!</SubTitle>;
      winPerson = null;
    } else {
      winPersonBitoMat = <WinInformationBitoMat/>;
      winPerson = null;
    }
  }

  if (tournamentId === 87) {
    winPersonBitGet = <WinInformationBitGet/>;
    winPerson = null;
  }

  if (tournamentId === 120) {
    if (rank === 0 || rank > 3) {
      defeatInfo = <SubTitle className="SecondSubTitle">Thank you!</SubTitle>;
      winPerson = null;
    } else {
      winPersonBitoMat = <WinInformationCryptoSilesia/>;
      winPerson = null;
    }
  }


  const handleJoinClick = async () => {
    try {
      setLoad(!isload);
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7,secure: true, sameSite: 'none', domain });
        navigate(routes.HOME);
        return;
      }

      if (!joinedStatus) {
        await joinTournament(tournamentId);
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error) {
      if (error.error.status === 409 && error.error.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else {
        console.error('Error joining tournament:', error);
      }
    }
  };

  return (
    <Wrap>
      <SubTitle>Tournament summary</SubTitle>
      <Title>{attributes?.name}</Title>
      <ContentInformation>
        <RowInformation>
          <Text>Market:</Text>
          <Text>Cryptocurrency</Text>
        </RowInformation>
        <RowInformation>
          <Text>Trading pair:</Text>
          <Text>{tradingPair.length === 0 ? `` : tradingPair?.length > 1 ? `Multi assets` : tradingPair[0]?.attributes?.name}</Text>
        </RowInformation>
        <RowInformation>
          <Text>Tournament time:</Text>
          <Text>
            {(tournamentStatus === 'FINISHED') ? (
              <>Finished</>
            ) :
              <>Ends in {endDate}</>
            }
          </Text>
        </RowInformation>
        <RowInformation>
          <Text>Result:</Text>
          <Text>
            {joinedStatus ? (
              <span className={`${percentageChange >= 0 ? 'up_state' : 'down_state'}`}>
                {parseTotalMoney} USDT
              </span>
            ) :
              <>-</>
            }
          </Text>
        </RowInformation>
        <RowInformation>
          <Text>Profit/Loss:</Text>
          <Text>
            {joinedStatus ? (
              <span className={`${percentageChange >= 0 ? 'up_state' : 'down_state'}`}>
                {percentageTotalss.toFixed(2)} USDT ({percentageChange >= 0 ? '+': percentageChange <= 0 ? '' : ''}{percentageChange.toFixed(4)}%)
              </span>
            ) :
              <>-</>
            }
          </Text>
        </RowInformation>
        {joinedStatus ? (
          <RowInformation>
            <Text>Reward:</Text>
            <Text>
              {displayRewardPool}
            </Text>
          </RowInformation>
        ) : null }
      </ContentInformation>

      {!joinedStatus && (tournamentStatus === 'FINISHED') ? (
        <>
          {!isLoggedIn ? (
            <ButtonFilled className="btn_large" type="submit" onClick={handleJoinClick}>Log in</ButtonFilled>
          ) :
            <ButtonFilled className="btn_large" type="submit" disabled>Finished</ButtonFilled>
          }
        </>
      ) :
        <>
          <WinWrap>
            {winPerson}
            {winPersonBitoMat}
            {winPersonBitGet}
            {winPersonCryptoSilesia}
          </WinWrap>

          <ContentInformationCenter>
            <Text>Your position:</Text>
            <Position>{rank}</Position>
            <SubText>(out of {participantsCount} participants)</SubText>
          </ContentInformationCenter>
          {defeatInfo}
        </>
      }

    </Wrap>
  );
};
export default JoinEnd;
