import styled from 'styles/styled';
import imgArrow from '../../../assets/icons/angle-left-solid.svg';

export const LinkText = styled.p`
  background-image: url(${imgArrow});
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  margin: 10px 0;
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const Article = styled.div`
  float: Left;
  width: calc(100% - 292px);
  position: relative;
  @media (min-width: 1000px) {
    @media (max-height: 650px) {
      height: 650px;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    float: none;
    .hideMobile {
      display: none;
    }
  }
  @media (max-width: 720px) {
    height: calc(100vh - 98px);
    height: calc(100svh - 98px);
  }
`;

export const Aside = styled.div`
  float: right;
  width: 282px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 1000px) {
    width: 100%;
    height: initial;
    float: none;
    &.hideMobile {
      display: none;
    }
  }
  .Margin {
    .TournamentRankingBox {
      max-height: calc(100vh - 237px);
      min-height: 873px;
      ul {
        max-height: calc(100vh - 377px);
        min-height: 500px;
      }
    }
  }
  .TournamentRankingBox {
    max-height: calc(100vh - 237px);
    min-height: 798px;
    ul {
      max-height: calc(100vh - 377px);
      min-height: 500px;
    }
  }
`;

export const ContentEndInformation = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 10px 0;
  width: 100%;
  margin-bottom: 10px;
  min-height: calc(100vh - 75px);
  min-height: calc(100svh - 75px);
  @media (max-width: 720px) {
    min-height: calc(100vh - 115px);
    min-height: calc(100svh - 115px);
  }
`;

export const BoxEndInformation = styled.div`
  text-align: center;
  button {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const RwdContent = styled.div`
  @media (max-width: 720px) {
    border-radius: 8px;
    border-radius: 8px;
    background-color: #15191E;
    padding: 8px 10px;
  }
`;

export const TitleMobile = styled.h4`
  display: none;
  @media (max-width: 720px) {
    display: block;
    font-size: 14px;
    color: #848484;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const FixedAside = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 100%;
`;

export const Load = styled.div`
  position: absolute;
  width: 100%:
  height: 100vh;
  background-color: red;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const ColWrap = styled.div`
  position: relative;
  min-height: calc(100vh - 76px);
  min-height: calc(100svh - 76px);
  @media (max-width: 1000px) {
    width: 100%;
    float: none;
  }
  @media (max-width: 720px) {
    min-height: inherit;
  }
  .hide {
    display: none;
  }
  &.oneBox {
    .JoinWrap {
      height: calc(100vh - 74px);
      height: calc(100svh - 74px);
      min-height: 964px;
      @media (max-width: 1000px) {
        height: calc(100svh - 76px);
      }
      @media (max-width: 720px) {
        min-height: 600px;
        height: calc(100svh - 140px);
      }
    }
  }
  &.twoBox {
    .JoinWrap {
      height: calc(100vh - 237px);
      height: calc(100svh - 237px);
      min-height: 798px;
      @media (max-width: 1000px) {
        height: calc(100svh - 76px);
      }
      @media (max-width: 720px) {
        min-height: 600px;
        height: calc(100svh - 80px);
        @media(max-height: 730px) {
          min-height: calc(100svh - 80px);
        }
      }
    }
    .TradeHistory {
      position: relative;
      bottom: 0;
      display: inline-block;
      margin-bottom: 10px;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
`;

export const LoadFullPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  height: 100vh;
  justify-content: center;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
