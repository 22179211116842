import React, { ReactNode } from 'react';
import LogoDetails from '../../../assets/fullbodyJoin.png';
import LogoDetailsMobile from '../../../assets/joinB_mobile.png';

import LogoDetailsBl from '../../../assets/BL/DesktopBLIntro.png';
import LogoDetailsMobileBl from '../../../assets/BL/joinBl_mobile.png';

import { useTournament } from 'context/TournamentContext';

import {
  Wrapper,
  Content,
  Center,
  TopContent,
} from './styled';

interface Props {
  children: ReactNode;
}

const JoinWrap = ({ children }: Props) => {
  const { tournamentDetails } = useTournament();
  const tournamentId = tournamentDetails?.data?.id;

  return (
  <Wrapper className="JoinWrap">
    <Center>
      <TopContent>

      {(tournamentId === 122 ) ? (
        <>
        <img src={LogoDetailsBl} className="DesktopImg" alt="Img Logo Details" />
        <img src={LogoDetailsMobileBl} className="MobileImg" alt="Img Logo Details" />
        </>
      ) :
        <>
          <img src={LogoDetails} className="DesktopImg" alt="Img Logo Details" />
          <img src={LogoDetailsMobile} className="MobileImg" alt="Img Logo Details" />
        </>
      }
      </TopContent>
      <Content>
        {children}
      </Content>
    </Center>
  </Wrapper>
  );
};

export default JoinWrap;
