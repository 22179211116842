import React, { useState, useContext } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import SocketContext from 'utils/hooks/useSocket';

import {
  TitleModal,
  TextModal,
  Row,
  Text,
  Amount,
  InputText,
  ButtonModal,
  LoadButton,
  LoadWrap,
  Wrap,
} from './styled';

const SetTPSL = ({ takeProfit, setTakeProfit, stopLoss, setStopLoss, onAccept, onCancel }) => {
  const { selectedCoinValue, selectedCoin } = useContext(SocketContext);
  const [isTrading] = useState(false);

  let shortNameSelectedCoin = selectedCoin?.name?.split('/')[0];
  let price = selectedCoinValue.last;

  return (
    <Wrap>
      <TitleModal>Set your Take Profit/Stop Loss</TitleModal>
      <TextModal>Define your Take Profit to lock in profits<br />and Stop Loss to limit losses</TextModal>
      <Row className="DarkInfo">
        <Text>Market price</Text>
        <Amount>{price} USDT</Amount>
      </Row>
      <Row className="RowInput">
        <Text className="TextSpecial">Take Profit </Text>
        <InputText
          type="number"
          pattern="\d*(\.\d+)?"
          step="0.0001"
          min="0"
          value={takeProfit}
          onChange={(e) => setTakeProfit(e.target.value)}
        />
        <Amount className="AmountSpecial">{shortNameSelectedCoin}</Amount>
      </Row>

      <Row className="RowInput">
        <Text className="TextSpecial">Stop Loss</Text>
        <InputText
          type="number"
          pattern="\d*(\.\d+)?"
          step="0.0001"
          min="0"
          value={stopLoss}
          onChange={(e) => setStopLoss(e.target.value)}
        />
        <Amount className="AmountSpecial">{shortNameSelectedCoin}</Amount>
      </Row>

      <ButtonModal>
        <div>
          {isTrading ?
            <LoadButton>
              <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
            </LoadButton>
          :
            <ButtonFilled
              type="button"
              onClick={onCancel}
            >
              Cancel
            </ButtonFilled>
          }
        </div>
        <div>
          {isTrading ?
            <LoadButton>
              <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
            </LoadButton>
          :
            <ButtonFilled
              type="button"
              onClick={onAccept}
            >
              Accept
            </ButtonFilled>
          }
        </div>
      </ButtonModal>
    </Wrap>
  );
};

export default SetTPSL;
