import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 10px;
  left: 0;
  right: 0;
`;

export const Wrap = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

export const TableWrap = styled.div`
  height: 88px;
  min-height: 88px;
  overflow: auto;
`;

export const Table = styled.table`
  width: 90%;
`;

export const Td = styled.td`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #EFEFEF;
  text-align: center;
  padding: 0 3px 3px 3px;
  min-width: 70px;
  &.up_state {
    color: #52D381;
  }
  &.detail_state {
    color: #DBB230;
  }
  &.down_state {
    color: #FF4B4A;
  }
  &:first-child {
    width: 90px;
  }
  span {
    width: 66px;
    border-radius: 7px;
    line-height: initial;
    font-weight: 700;
    padding: 2px;
    display: inline-block;
    text-align: center;
    color: #696969;
    border: 1px solid #696969;
    &.up_color {
      color: #52D381;
    }
    &.down_color {
      color: #FF4B4A;
    }
  }
`;

export const Th = styled.th`
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #696969;
  padding: 0 3px 12px 3px;
  text-align: center;
  position: sticky;
  min-width: 70px;
  top: 0;
  background-color: #15191E;
  &:first-child {
    width: 90px;
  }
`;

export const Tab = styled.div`
  margin-bottom: 15px;
`;

export const Button = styled.button`
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #fff;
    }
  }
`;

export const IconCurrency = styled.div`
  width: 26px;
  height: 26px;
  margin: 6px;
  background-color: #ddd;
  border-radius: 150px;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const InfoMargin = styled.span`
  color: #090B0E;
  background-color: #52D381;
  font-size: 10px;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  &.down_color {
    background-color: #FF4B4A;
  }
  &.up_color {
    background-color: #52D381;
  }
`;

export const Leverage = styled.span`
  color: #090B0E;
  font-size: 10px;
  background-color: #DBB230;
  padding: 3px 5px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
`;

export const CurrencyInfo = styled.h4`
  font-size: 11px;
  font-weight: bold;
  color: #efefef;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ColWrap = styled.div`
  display: flex;
  padding: 9px 6px 9px 0;
  justify-content: space-between;
  min-height: 75px;
  display: flex;
  align-items: center;
  &.editColWrap {
    justify-content: center;
  }
`;

export const SmallBox = styled.div`
  border: 1px dashed #EFEFEF;
  border-radius: 4px;
  background-color: #292B2E;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: initial;
  padding: 5px 8px;
  cursor: pointer;
  h5 {
    width: initial;
    line-height: 11px;
    margin-left: 10px;
    &.down_color {
      color: #FF4B4A;
    }
    &.up_color {
      color: #52D381;
    }
    span {
      width: initial;
      margin: 0 8px 0 0;
    }
  }
`;

export const SmallText = styled.h4`
  font-size: 8px;
  font-weight: 400;
  color: #EFEFEF;
  margin-bottom: 2px;
`;

export const Text = styled.h5`
  font-weight: 400;
  line-height: 12px;
  font-size: 8px;
  color: #EFEFEF;
  display: flex;
  width: 100%;
  span {
    font-weight: 300;
    color: #696969;
    text-align: right;
    width: 50%;
    margin-right: 5px;
  }
`;

export const EditBoxWrap = styled.div`
  p {
    font-size: 12px;
    color: #efefef;
    line-height: 15px;
    text-align: center;
  }
  button {
    width: 50%;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: space-between;
  .button {
    width: calc(50% - 4px);
  }
`;

export const ListWrap = styled.div`
  border-radius: 14px;
  border: 1px solid #696969;
  background-color: #000;
  min-height: 20px;
  overflow: hidden;
  margin-bottom: 8px;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  button {
    background-color: #292B2E;
    margin: 0 6px;
    color: #fff;
    min-height: 35px;
    height: 35px;
    font-size: 13px;
    min-width: 100px;
    &:hover {
      background-color: #292B2E;
    }
  }
  p.up_color {
    color: #52D381;
  }
`;

export const ListWrapCol = styled.div`
  display: flex;
  align-items: center;
  .box_right {
    width: inherit;
    margin-left: 20px;
    span {
      line-height: initial;
    }
  }
`;

export const ListText = styled.div`
  display: flex;
  h5 {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin: 0 10px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    @media (max-width: 1400px) {
      font-size: 8px;
      line-height: inherit;
    }
    span {
      width: inherit;
      text-align: center;
      line-height: initial;
      margin: 0;
      font-weight: 600;
      @media (max-width: 1400px) {
        margin-bottom: 5px;
      }
    }
  }
  .info_text {
    margin-left: 20px;
    font-size: 13px;
  }
`;

export const ListRightButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ColLeft = styled.div`
  display: flex;
  align-items: center;
`;
