import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData, closeMargin, cancelMargin } from 'api/socketService';
import SocketContext, { useSocket } from 'utils/hooks/useSocket';
import { ButtonFilled } from 'components/ui/Button';
import { useWalletData } from 'context/WalletDataContext';

import {
  Wrap,
  Tab,
  Button,
  Box,
  BoxRow,
  BoxLeft,
  BoxRight,
  IconCurrency,
  BoxInfo,
  CurrencyInfo,
  AmountInfo,
  Percentages,
  RowInfo,
  InfoMargin,
  Rang,
  BoxContent,
  Col,
  ColWrap,
  ColRow,
  Text,
  SmallBox,
  SmallText,
  EditBoxWrap,
  BoxButton,
  WrapListTransaction,
  RowTop,
  RowContent,
  BoxProfit,
  Amount,
  ListPerformance,
  RowText,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  transaction_type: string;
  leverage: number;
  status: string;
  is_settled: boolean;
  used_funds: number;
  liquidation_price: number;
  description: string;
  limit?: number;
  take_profit?: number;
  stop_loss?: number;
};

const TradeHistoryMarginMobile = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };
  const [isOpenTransaction, setOpenTransaction] = useState(true);
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { tournamentDetails } = useSocket();
  const { walletData } = useWalletData();
  const { allCoinsValues, tradingUpdates } = useContext(SocketContext);

  function calculatePerformance(data, selectedCoinPrice) {
    let totalCoins = 0;
    let totalCost = 0;
    let weightedPriceSum = 0
    let totalAmountBought = 0;

    data?.forEach(trade => {
      if (trade.type === 'buy') {
        totalCoins += trade.amount;
        totalCost += trade.price;
        weightedPriceSum += trade.amount * trade.market_value;
        totalAmountBought += trade.amount;
      } else if (trade.type === 'sell') {
        totalCoins -= trade.amount;
        totalCost -= trade.price;
      }
    });

    const avgPrice = weightedPriceSum / totalAmountBought;
    const currentValue = totalCoins * selectedCoinPrice; // Current total value in USDT
    const plUSDT = currentValue - totalCost
    const plPercent = (currentValue*100 / totalCost) - 100;

    return {
      totalCoins: totalCoins,
      totalCost: totalCost,
      avgPrice: avgPrice,
      currentValue: currentValue,
      plUSDT: plUSDT,
      plPercent: plPercent
    };
  }

  const updateTrading = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      setTradingData(data);
    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    const tournamentId = tournamentDetails?.data?.id;
    const tradeType = tournamentDetails?.data?.attributes?.trade_type;

    if (tournamentId) {
      requestTradingData(tournamentId, tradeType, updateTrading);
    }
  };

  const [pairTradesData, updatePairTradesData] = useState(null)
  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.amount !== currentWalletData[i]?.amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  let tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');

  useEffect(()=>{
    fetchTradingData();
  },[currentWalletData ])

  useEffect(() => {
    const pairTradesDataTemp = {};
    const pairTradesGroups = {};
    tradingData.forEach(trade => {
      const pair = trade.trading_pair;
      if (!pairTradesGroups[pair]) {
        pairTradesGroups[pair] = [];
      }
      pairTradesGroups[pair].push(trade);
      for (let key in pairTradesGroups) {
        let shortName = key.split('/')[0].toLowerCase();
        let pair = pairTradesGroups[key]
        if(allCoinsValues && allCoinsValues[shortName]){
          let data = calculatePerformance(pair, allCoinsValues[shortName].last);
          pairTradesDataTemp[key] = data
        }
      }
  });
  updatePairTradesData(pairTradesDataTemp)
}, [allCoinsValues, tradingData]);

  const filterTradesByStatus = (statuses, isSettled = null) => {
    return tradingData.filter(trade => {
      if (isSettled !== null) {
        return statuses.includes(trade.status) && trade.is_settled === isSettled;
      }
      return statuses.includes(trade.status);
    });
  };

  const calculateProfit = (currentPrice, trade) => {
    const multiplier = trade.transaction_type === 'long' ? 1 : -1;
    const profit = ((currentPrice - trade.price) / trade.price) * trade.used_funds * trade.leverage * multiplier;
    const profitPercent = ((currentPrice - trade.price) / trade.price) * 100 * trade.leverage * multiplier;
    return { profit, profitPercent };
  };

  // Update tradingData whenever tradingUpdates changes
  useEffect(() => {
    if (tradingUpdates && Array.isArray(tradingUpdates)) {
      setTradingData(tradingUpdates);
    }
  }, [tradingUpdates]);

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Open</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Pending</Button>
        <Button className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>History</Button>
      </Tab>

      {activeTab === "tab1" ?
        <WrapListTransaction>
          {filterTradesByStatus(["filled"], false).map(trade => {
            const currentPrice = allCoinsValues[trade.trading_pair.split('/')[0].toLowerCase()]?.last || 0;
            const { profit, profitPercent } = calculateProfit(currentPrice, trade);
            return (
              <Box key={trade.id}>
                <BoxRow>
                  <BoxLeft>
                    <IconCurrency />
                    <BoxInfo>
                      <RowInfo>
                        <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                          {trade.transaction_type === "long" ? "Long" : "Short"}
                        </InfoMargin>
                        <Rang>X{trade.leverage}</Rang>
                      </RowInfo>
                      <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                    </BoxInfo>
                  </BoxLeft>
                  <BoxRight className={profit >= 0 ? "up_color" : "down_color"}>
                    <span>Profit</span>
                    <AmountInfo>{profit.toFixed(2)} USDT</AmountInfo>
                    <Percentages>({profitPercent.toFixed(2)}%)</Percentages>
                  </BoxRight>
                </BoxRow>

                <BoxContent>
                  {isOpenTransaction &&
                    <>
                      <ColWrap>
                        <Col className="colLeft">
                          <ColRow>
                            <Text><span>Used Funds</span>{trade.used_funds} USDT</Text>
                          </ColRow>
                          <ColRow>
                            <Text><span>Amount</span>{trade.amount} {trade.trading_pair.replace('/USDT', '')}</Text>
                          </ColRow>
                          <ColRow>
                            <Text><span>Price</span>{trade.price} USDT</Text>
                          </ColRow>
                          <ColRow>
                            <Text><span>Liquidation Price</span>{trade.liquidation_price} USDT</Text>
                          </ColRow>
                        </Col>
                        <Col>
                          <SmallBox>
                            <SmallText>Tap to edit</SmallText>
                            <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                            <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                          </SmallBox>
                        </Col>
                      </ColWrap>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        Close position
                      </ButtonFilled>
                    </>
                  }

                  {!isOpenTransaction &&
                    <EditBoxWrap>
                      <ColWrap className="editColWrap">
                      <p>Are you sure<br />you want to close this position?</p>
                      </ColWrap>
                      <BoxButton>
                        <ButtonFilled
                          className="button"
                          type="button"
                          onClick={() => {
                            closeMargin(trade.id, () => {});
                            setOpenTransaction(!isOpenTransaction);
                          }}
                        >
                          Yes
                        </ButtonFilled>
                        <ButtonFilled
                          className="button"
                          type="button"
                          onClick={() => setOpenTransaction(!isOpenTransaction)}
                        >
                          No
                        </ButtonFilled>
                      </BoxButton>
                    </EditBoxWrap>
                  }
                </BoxContent>
              </Box>
            );
          })}
        </WrapListTransaction>
      : null}

      {activeTab === "tab2" ?
        <WrapListTransaction>
          {filterTradesByStatus(["pending"]).map(trade => (
            <Box key={trade.id}>
              <BoxRow>
                <BoxLeft>
                  <IconCurrency />
                  <BoxInfo>
                    <RowInfo>
                      <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                        {trade.transaction_type === "long" ? "Long" : "Short"}
                      </InfoMargin>
                      <Rang>X{trade.leverage}</Rang>
                    </RowInfo>
                    <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                  </BoxInfo>
                </BoxLeft>
                <BoxRight>
                  <span>Limit</span>
                  <AmountInfo>{trade.limit} USDT</AmountInfo>
                </BoxRight>
              </BoxRow>

              <BoxContent>
                {isOpenTransaction &&
                  <>
                    <ColWrap>
                      <Col className="colLeft">
                        <ColRow>
                          <Text><span>Used Funds</span>{trade.used_funds} USDT</Text>
                        </ColRow>
                        <ColRow>
                          <Text><span>Amount</span>{trade.amount} {trade.trading_pair.replace('/USDT', '')}</Text>
                        </ColRow>
                      </Col>
                      <Col>
                        <SmallBox>
                          <SmallText>Tap to edit</SmallText>
                          <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                          <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                        </SmallBox>
                      </Col>
                    </ColWrap>
                    <ButtonFilled
                      className="button"
                      type="button"
                      onClick={() => setOpenTransaction(!isOpenTransaction)}
                    >
                      Cancel order
                    </ButtonFilled>
                  </>
                }

                {!isOpenTransaction &&
                  <EditBoxWrap>
                    <ColWrap className="editColWrap">
                    <p>Are you sure<br />you want to cancel this order?</p>
                    </ColWrap>
                    <BoxButton>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => {
                          cancelMargin(trade.id, () => { });
                          setOpenTransaction(!isOpenTransaction);
                        }}
                      >
                        Yes
                      </ButtonFilled>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        No
                      </ButtonFilled>
                    </BoxButton>
                  </EditBoxWrap>
                }
              </BoxContent>
            </Box>
          ))}
        </WrapListTransaction>
      : null}

      {activeTab === "tab3" ?
        <ListPerformance>
          {filterTradesByStatus(["filled", "canceled"]).map(trade => {
            const currentPrice = allCoinsValues[trade.trading_pair.split('/')[0].toLowerCase()]?.last || 0;
            const { profit } = calculateProfit(currentPrice, trade);
            return (
              <li key={trade.id}>
                <div>
                  <RowTop>
                    <>
                      <span>{trade.trading_pair}</span>
                    </>
                    <>
                      <span>{new Date(trade.createdAt).toLocaleString()}</span>
                    </>
                  </RowTop>

                  <RowContent>
                    <Col className="col_left">
                      <RowText className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                        <span>Type</span>
                        <p>{trade.description}</p>
                      </RowText>
                      <RowText className="detail_color">
                        <span>Leverage</span>
                        <p>X{trade.leverage}</p>
                      </RowText>
                      <RowText>
                        <span>Used funds</span>
                        <p>{trade.used_funds} USDT</p>
                      </RowText>
                      <RowText>
                        <span>Amount</span>
                        <p>{trade.amount} {trade.trading_pair.replace('/USDT', '')}</p>
                      </RowText>
                      <RowText>
                        <span>Price</span>
                        <p>{trade.price} USDT</p>
                      </RowText>
                    </Col>
                      <Col className="col_right">
                        <span className={trade.status === "filled" ? "up_color" : "detail_color"}>{trade.status.charAt(0).toUpperCase() + trade.status.slice(1)}</span>
                        {trade.status === "filled" && trade.is_settled && (
                        <BoxProfit>
                          <p>Profit</p>
                          <Amount className={profit < 0 ? "down_color" : "up_color"}>{profit.toFixed(2)} USDT</Amount>
                        </BoxProfit>
                    )}
                      </Col>
                  </RowContent>
                </div>
              </li>
            );
          })}
        </ListPerformance>
      : null}
    </Wrap>
  );
};
export default TradeHistoryMarginMobile;
