import React from 'react';
import OpenLong from 'components/ui/OpenLong';
import OpenShort from 'components/ui/OpenShort';

import {
  Box,
  Col,
  Wrap,
  MobileView,
} from './styled';

const ShortLong = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = props;

  return (
    <Box>
        <Wrap>
          <MobileView>
            <OpenLong tournamentDetails={tournamentDetails} />
          </MobileView>
          <Col>
            <OpenLong />
          </Col>
          <Col>
            <OpenShort />
          </Col>
        </Wrap>
    </Box>
  );
};

export default ShortLong;
