import { checkUserDetails } from 'api/authService';
import { useUser } from 'api/userService';
import { useEffect } from 'react';

const IframeHandler = () => {
  const { isLoggedIn } = useUser();
  const handlePostMessage = async (event) => {
      const landingPageOrigin = process.env.REACT_APP_LANDING_PAGE;

      if (event.origin !== landingPageOrigin) {
          return;
      }

      if (event.data === 'getUserStatus') {
          try {
              const userDetails = await checkUserDetails();
              event.source.postMessage({ isLoggedIn: true, userDetails }, event.origin);
          } catch {
              event.source.postMessage({ isLoggedIn: false }, event.origin);
          }
      }
  };

  useEffect(() => {
      window.addEventListener('message', handlePostMessage);

      return () => {
          window.removeEventListener('message', handlePostMessage);
      };
  }, []);

  return null;
};

export default IframeHandler;
